<template>
  <div>
    <v-container>
      <div class="mt-6 mb-2">
        <div class="text-h5 text-center" v-if="status == 0">正在请求</div>
        <div class="text-h5 text-center red--text" v-if="status == -1">
          失败
        </div>
        <div class="text-h5 text-center" v-if="status == 1">激活成功</div>
        <div class="text--secondary text-center">{{ text }}</div>
      </div>
      <v-row justify="center" class="mt-4">
        <v-btn to="/" class="mx-auto" depressed color="primary">返回主页</v-btn>
      </v-row>
    </v-container>
    <v-container v-if="status == 0"> </v-container>
  </div>
</template>

<script>
export default {
  name: "activate",
  data: function () {
    return {
      status: 0,
      text: "请稍等",
      token: "",
    };
  },
  mounted: function () {
    this.token = this.$route.query.token;
    if (this.token == null || this.token == "") {
      this.status = -1;
      this.text = "参数丢失";
      return;
    }

    this.axios
      .post("/api/email/activate", this.$route.query)
      .then((res) => {
        if (res.data.status != 0) {
          this.status = -1;
          this.text = res.data.msg;
          return;
        }else{
          this.status = 1;
          this.text = res.data.msg;
          return;
        }
      })
      .catch((err) => {
        this.status = -1;
        this.text = err;
        return;
      });
  },
};
</script>

<style></style>
